import React from "react";
import { Link } from "react-router-dom";
import expenseMainCss from "./css/ExpenseMain.module.css";

const ExpenseMain = () => {
  const expenseMenu = [
    { title: "Add Expense", link: "/expense/add-new-expense", icon: "" },
    { title: "Pending Expense", link: "/expense/pending", icon: "" },
    { title: "Expense Hisrory", link: "/expense/history", icon: "" },
    { title: "Declined Expense", link: "/expense/decline", icon: "" },
    { title: "Expense Head", link: "/expense/head", icon: "" },
    { title: "Expense Group", link: "/expense/group", icon: "" },
  ];
  return (
    <div className="p-4 pb-[70px]">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {expenseMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
              key={index}
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ExpenseMain;

{
  /* <> 
<div className={ExpenseMainCss.menupart}>
    <ul className={ExpenseMainCss.menuitems}>
      
        
        <NavLink to={"/admin/expenses/declined_expense"}>
            <li >Declined Expense</li>
        </NavLink>
        <NavLink to={"/admin/expenses/expense_head"}>
            <li >Expense Head</li>
        </NavLink>
        <NavLink to={"/admin/expenses/expense_group"}>
            <li >Expense Group</li>
        </NavLink>
    </ul>
</div>
<div>
</div>
</> */
}
