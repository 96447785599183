import { deleteCookie } from "cookies-next";
import { FaBars, FaTimes } from "react-icons/fa";
import { RxDropdownMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/anayase_logo.png";
import { selectMenuShow, setMenuShow } from "./ReduxFiles/Slices/ProductSlice";
import {
  selectUserInfo,
  setIsLogedIn,
  setUserInfo,
} from "./ReduxFiles/Slices/UserSlice";
import { useCallback, useEffect, useRef, useState } from "react";
export default function TopBar() {
  const userInfo = useSelector(selectUserInfo);
  const showMenu = useSelector(selectMenuShow);
  const myNavigate = useNavigate();
  const myDispatch = useDispatch();

  function onLogoutClick() {
    deleteCookie("anyase_admin_user");
    deleteCookie("anyase_admin_user");
    myNavigate("/login");
    myDispatch(setIsLogedIn(false));
    myDispatch(setUserInfo({}));
  }
  function onMenuShowClick() {
    myDispatch(setMenuShow(true));
  }
  function onMenuHideClick() {
    myDispatch(setMenuShow(false));
  }
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  function onProfileClick() {
    setShowProfileOptions(true);
  }
  const dropdownRef = useRef();
  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowProfileOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div className="w-full bg-white sticky top-0 z-[100] shadow-md py-2 print:hidden">
      <div className="flex gap-4 px-4 items-center justify-between md:px-12">
        <div className="text-2xl md:hidden">
          {showMenu ? (
            <FaTimes onClick={onMenuHideClick} />
          ) : (
            <FaBars onClick={onMenuShowClick} />
          )}
        </div>

        <div className="gap-3 justify-between flex relative">
          <Link to={"/"} className="h-8 ">
            <img src={logo} alt="site logo" className="w-auto h-full" />
          </Link>
        </div>
        <button onClick={onProfileClick} className="text-2xl md:hidden">
          <RxDropdownMenu />
        </button>
        <div
          className={`absolute top-12 bg-white  shadow-shadow_box2 right-4 md:flex ${
            showProfileOptions ? "visible" : "hidden"
          } md:shadow-none md:relative md:top-0`}
        >
          <div
            ref={dropdownRef}
            className="flex flex-col gap-3 text-right p-4 md:flex-row md:p-0"
          >
            <Link to={"/courier-ratio"}>Check Customer</Link>

            <p>
              <Link to={"/profile"}>{userInfo.userName}</Link>
            </p>
            <p onClick={onLogoutClick}>Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
}
