import axios from "axios";
import Papa from "papaparse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  fetchInitCustomer,
  fetchSearchedCustomer,
  selectCustomerStatus,
  selectInitCustomer,
} from "../../../ReduxFiles/Slices/CustomerSlice";
import { API_LINK } from "../../../api/APILink";
import { GetLimitCustomer } from "../../../api/CustomerApi";
import BrandSelectModel from "./BrandSelectModel";
const CrmMain = () => {
  // const [allCustomer, setAllCustomer] = useState()
  const [addedNumber, setAddedNumber] = useState([]);
  const [inputedNumber, setInputedNumber] = useState({});
  const [messageText, setMessageText] = useState();
  const [characterCount, setCharacterCount] = useState(0);

  const [messageCount, setMessageCount] = useState(0);
  const dispatch = useDispatch();
  const customerStatus = useSelector(selectCustomerStatus);
  const allCustomer = useSelector(selectInitCustomer);
  const [progress, setProgress] = useState({
    total: 0,
    completed: 0,
    percentage: 0,
    failed: 0,
    failedNumber: [],
    failedPercentage: 0,
  });
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (customerStatus === "idle") {
      dispatch(fetchInitCustomer(10));
    }
  }, [customerStatus, dispatch]);

  function onRemoveClick(item) {
    const updatedNumber = addedNumber.filter(
      (element) => element.phone !== item.phone
    );
    setAddedNumber(updatedNumber);
  }

  function addToList() {
    if (inputedNumber) {
      setAddedNumber([inputedNumber, ...addedNumber]);
      setInputedNumber();
    } else {
      alert("Write a number to add");
    }
  }
  async function onSendClick() {
    if (messageText) {
      let stringArr = messageText.replaceAll("%", "%25").split("\n");
      let finalText = "";
      stringArr.forEach((element) => {
        finalText += element + "%0a";
      });

      if (addedNumber.length > 0) {
        const totalNumbers = addedNumber.length;
        let completedCount = 0;
        let failedCount = 0;
        let failedNumbers = [];
        let failedPercentage = 0;
        setDisableButton(true);

        const updateProgress = () => {
          completedCount++;
          const progress = (completedCount / totalNumbers) * 100;
          failedPercentage = (failedCount / totalNumbers) * 100;
          setProgress({
            total: totalNumbers,
            completed: completedCount,
            percentage: progress,
            failed: failedCount,
            failedNumber: failedNumbers,
            failedPercentage: failedPercentage,
          });
          // Update UI with progress (e.g., setProgress(progress))
        };

        const promises = addedNumber.map(async (element) => {
          try {
            await axios.post(
              `${API_LINK}sms/test-msg`,
              {
                phone: element.phone,
                message: finalText,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            updateProgress(); // Update progress after each successful message
          } catch (error) {
            console.error(`Failed to send message to ${element.phone}:`, error);
            updateProgress(); // Update progress even if there's an error
            failedCount++;
            failedNumbers.push(element.phone);
          }
        });

        await Promise.all(promises); // Wait for all messages to be sent
        setAddedNumber([]); // Clear the added numbers dfsad
        setMessageText(""); // Clear the message text
        setDisableButton(false);
      } else {
        alert("Please add at least one number to send SMS");
      }
    } else {
      alert("You cannot send a blank SMS");
    }
  }

  const wordCount = useCallback(() => {
    let count = 0;
    for (let index = 0; index < messageText?.length; index++) {
      count++;
    }
    if (count > 0) {
      let smsNumber = Math.floor(count / 161);
      setMessageCount(smsNumber + 1);
    } else {
      setMessageCount(0);
    }
    setCharacterCount(count);
  }, [messageText]);

  useEffect(() => {
    wordCount();
  }, [wordCount]);

  function onCustomerChange(data) {
    // console.log(data.phone);
    setInputedNumber(data);
    // setSearchDate({ ...searchDate, customer_id: data.id })
  }
  const onInputDataChange = (inputValue, { action }) => {
    if (action === "input-change") {
      if (inputValue.length >= 3) {
        dispatch(fetchSearchedCustomer(inputValue));
      }

      setInputedNumber({ phone: inputValue });
    }
  };
  const fileInputRef = useRef(null);
  async function handleAllCustomer() {
    const response = await GetLimitCustomer();
    setAddedNumber(response);
  }
  function handleFileUpload(e) {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true, // If your CSV has a header row
        complete: (results) => {
          const data = results.data;
          const phone = data.filter((item) => item.phone);
          if (phone.length > 0) {
            setAddedNumber(uniqueNumbers(phone));
          } else {
            toast.error("No phone number found");
            setAddedNumber([]);
          }
          fileInputRef.current.value = null;
        },
        error: (error) => {
          console.error("Error parsing CSV", error);
        },
      });
    }
  }

  function uniqueNumbers(phoneNumbers) {
    // Create a Set to store unique phone numbers
    const uniquePhones = new Set();

    // Filter the array to remove duplicates
    const uniqueData = phoneNumbers.filter((item) => {
      // Check if the phone number is already in the Set
      if (!uniquePhones.has(item.phone) && item.phone !== "") {
        // Add the phone number to the Set
        uniquePhones.add(item.phone);
        return true;
      }
      return false;
    });

    return uniqueData;
  }
  const [popUp, setPopUp] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  return (
    <div className="w-[96%] mx-auto bg-gray-100 p-4">
      {popUp && (
        <BrandSelectModel
          popUp={popUp}
          setPopUp={setPopUp}
          setAddedNumber={setAddedNumber}
        />
      )}
      <p className="text-2xl font-bold text-center py-2">Send Message</p>
      <div className="bg-white p-4 rounded-lg flex gap-4 items-start">
        <div className="flex gap-4 flex-col w-1/2">
          <div className="flex gap-4 items-center">
            <div className="flex gap-2 items-center flex-1">
              <label className="text-nowrap">Contact List :</label>
              <Select
                options={allCustomer}
                getOptionLabel={(option) => `${option.name} - ${option.phone}`}
                getOptionValue={(option) => option.id}
                onChange={onCustomerChange}
                onInputChange={onInputDataChange}
                className="w-full"
              />
            </div>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={addToList}
            >
              Add to List
            </button>
          </div>
          <div className="flex gap-2">
            <button
              onClick={() => {
                setPopUp(true);
                document.body.style.overflow = "hidden";
              }}
              className="bg-teal-500 text-white px-4 py-2 rounded-md"
            >
              Brand Wise
            </button>
            <button
              onClick={handleAllCustomer}
              className="bg-green-500 text-white px-4 py-2 rounded-md"
            >
              All Customer
            </button>
            <button
              onClick={() => {
                fileInputRef.current.click();
              }}
              className="bg-purple-500 text-white px-4 py-2 rounded-md"
            >
              Upload CSV
            </button>
            <input
              type="file"
              className="hidden"
              accept=".csv"
              ref={fileInputRef}
              onChange={handleFileUpload}
            />
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={() => {
                setAddedNumber([]);
                setProgress({
                  total: 0,
                  completed: 0,
                  percentage: 0,
                  failed: 0,
                  failedNumber: [],
                  failedPercentage: 0,
                });
              }}
            >
              Reset
            </button>
          </div>

          <div>
            <div className="custom-table">
              <table>
                <thead>
                  <tr>
                    <th>SL</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {addedNumber?.map((item, index) => {
                    return (
                      <tr key={index + 10000}>
                        <td>{index + 1}</td>
                        <td className="text-left">{item.name}</td>
                        <td>{item.phone}</td>
                        <td>
                          <RiDeleteBin6Line
                            onClick={() => {
                              onRemoveClick(item);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="w-1/2 flex flex-col gap-4">
          <textarea
            rows="6"
            cols="45"
            placeholder="write your message here"
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            value={messageText ? messageText : ""}
            className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-1 focus:ring-blue-500"
          ></textarea>
          <div className="flex gap-4 items-center">
            <div className="flex gap-2 items-center">
              <p>Character {characterCount}</p>

              <p>Message {messageCount}</p>
            </div>
          </div>
          <div>
            <div>
              <p>
                {progress.completed} of {progress.total}
              </p>
            </div>
            <div className="w-full h-4 bg-gray-200 rounded-full">
              <div
                className="h-4 bg-blue-500 rounded-full"
                style={{ width: `${progress.percentage}%` }}
              ></div>
            </div>
          </div>
          <div className="flex gap-2 justify-between">
            <p
              className="cursor-pointer text-red-500"
              onClick={() => {
                setShowFailed(!showFailed);
              }}
            >
              Failed : {progress.failed}
            </p>

            <p>Failed Percentage : {progress.failedPercentage}%</p>
          </div>
          <div className="flex justify-center">
            <button
              className={`bg-blue-500 text-white px-4 py-2 rounded-md w-full font-semibold ${
                disableButton ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={onSendClick}
              disabled={disableButton}
            >
              {disableButton ? "Sending..." : "Send SMS"}
            </button>
          </div>
          {showFailed && (
            <div className="w-1/2">
              <p className="text-red-500 font-bold">Failed Numbers</p>
              <div>
                {progress.failedNumber.map((item) => {
                  return <p key={item}>{item}</p>;
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className=" p-4"></div>
    </div>
  );
};

export default CrmMain;
