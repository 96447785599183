import pendingDetailCss from "./salesCss/PendingOrderDetails.module.css";

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GetSingleCustomer,
  GetSingleCustomerOrderAddress,
} from "../../api/CustomerApi";
import {
  SaveSummeryData,
  SaveTrackingData,
} from "../../api/OrderInformationApi";
import {
  GetDetailsOrderItems,
  GetSingleOrder,
  InformationMessage,
  UpdateOrderData,
} from "../../api/SalesApi";
import { selectUserInfo } from "../../ReduxFiles/Slices/UserSlice";

import { toast } from "react-toastify";
import ConfirmAlert from "../global/ConfirmAlert";
import GobackButton from "../global/GobackButton";

const PendingOrderDetails = () => {
  const userInfo = useSelector(selectUserInfo);
  const myNavigation = useNavigate();
  const myParams = useParams();
  const [tableData, setTableData] = useState([]);
  const [orderData, setOrderData] = useState();
  const [conShow, setConShow] = useState(false);
  const formRef = useRef();
  const [btnDisabled, setBtnDisabled] = useState(false);
  async function onApproveClick(e) {
    e.preventDefault();
    setBtnDisabled(true);
    const approval_note = e.target.approval_note.value;
    const priority = e.target.priority.value;
    const delivery_date = e.target.delivery_date.value;
    const special_instruction = e.target.special_instruction.value;

    let summeryData = {
      activity: `Order Confirmed by ${userInfo.fullName}`,
      ProssesedBy: userInfo.id,
      ForwardTo: "",
      deliveryDate: delivery_date,
      note: approval_note,
      sale_id: myParams.orderid,
      created_date: new Date().getTime(),
    };

    let orderInfo = {
      approval_note: approval_note,
      delivery_date: delivery_date,
      priority: priority,
      special_instruction: special_instruction,
      pending: 0,
      confirmed: 1,
      status: "Order Confirmed",
      CDby: userInfo.id,
    };

    let trackData = {
      sales_id: myParams.orderid,
      created_date: new Date().getTime(),
      note: "Your Order is Confirmed.",
    };
    // updating order data
    try {
      await UpdateOrderData(myParams.orderid, orderInfo);
      await SaveSummeryData(summeryData);
      await SaveTrackingData(trackData);
      setBtnDisabled(false);
      myNavigation("/sales/history");
    } catch (error) {
      toast.error(error.message);
    }

    // send confirmation message
    let myOrderMessage = `Dear Sir/Madam, Your order -${myParams.orderid} has been confirmed. Delivery date is ${delivery_date}.%0aThank You for shopping with us.%0aAnayase%0a01810169101`;
    try {
      let mPhone = orderData.Phone ? orderData.Phone : orderData.phone;
      await InformationMessage({
        phone: `88${mPhone}`,
        message: myOrderMessage,
      });
    } catch (error) {
      toast.error(`SMS : ${error.message}`);
    }
  }

  function onDeclineClick() {
    document.body.style.overflow = "hidden";
    setConShow(true);
  }

  async function clickYes() {
    const approval_note = formRef.current.approval_note.value;
    let summeryData = {
      ProssesedBy: userInfo.id,
      note: approval_note ? approval_note : "",
      sale_id: myParams.orderid,
      created_date: new Date().getTime(),
      activity: `Order Declined by ${userInfo.fullName}`,
    };

    try {
      await UpdateOrderData(myParams.orderid, { declined: 1, pending: 0 });
      await SaveSummeryData(summeryData);
    } catch (error) {
      toast.error(error.message);
    }

    let myOrderMessage = `Dear Sir, Your order -${myParams.orderid} has been canceled.%0aThank You for shopping with us.%0aAnayase`;

    try {
      let mPhone = orderData.Phone ? orderData.Phone : orderData.phone;
      await InformationMessage({
        phone: `88${mPhone}`,
        message: myOrderMessage,
      });
    } catch (error) {
      toast.error(`SMS : ${error.message}`);
    }

    setConShow(false);
    myNavigation("/sales/decline");
    document.body.style.overflow = "auto";
  }

  function clickNo() {
    document.body.style.overflow = "auto";
    setConShow(false);
  }

  function convertDate(time) {
    const dateInMillis = time * 1000;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myDate + " " + myTime;
  }

  function organizeData(myData) {
    let finalArr = [];
    let uniqueElements = myData?.slice();
    for (let index = 0; index < myData.length; index++) {
      const element = myData[index];
      // console.log(element)
      let tempArr = [];
      for (let mIndex = 0; mIndex < uniqueElements.length; mIndex++) {
        const mElement = uniqueElements[mIndex];
        if (mElement.product_id === element.product_id) {
          tempArr.push(mElement);
        }
      }

      if (tempArr.length > 0) {
        tempArr.forEach((element) => {
          uniqueElements.forEach((mElement, mIndex) => {
            if (mElement.id === element.id) {
              uniqueElements.splice(mIndex, 1);
            }
          });
        });

        finalArr.push(tempArr);
      }
    }
    setTableData(finalArr);
  }

  useEffect(() => {
    async function getAllData() {
      let myData = await GetSingleOrder(myParams.orderid);

      let myCustomer = await GetSingleCustomer(myData[0]?.userID);
      let userAddress = await GetSingleCustomerOrderAddress(
        myData[0]?.user_address_id
      );
      let getSSLdata = [];
      let finalData = {
        ...myData[0],
        order_created: myData[0].created_date,
        ...userAddress[0],
        ...myCustomer[0],
        ...getSSLdata[0],
        deliveryCharge: myData[0].deliveryCharge,
        id: myData[0].id,
      };
      // console.log(finalData)

      setOrderData(finalData);
      let morderItems = await GetDetailsOrderItems({ id: myParams.orderid });
      organizeData(morderItems);
    }
    getAllData();
  }, [myParams.orderid]);

  function getQTotal(item) {
    let total = 0;
    item?.forEach((element) => {
      total += element.quantity - parseInt(element.rquantity);
    });

    return total;
  }

  function totalAmmount(item) {
    let quantity = getQTotal(item);
    let total = 0;
    total += item[0].orPrice;
    return total * quantity;
  }
  function onPrintInvoice() {
    let okdata = {};
    okdata = { ...okdata, tableData, ...orderData };
    localStorage.setItem("invoiceData", JSON.stringify(okdata));
  }
  return (
    <section className={pendingDetailCss.wrapper}>
      {conShow ? (
        <ConfirmAlert
          message="Do you want to decline this order?"
          yesClick={clickYes}
          noClick={clickNo}
        />
      ) : (
        ""
      )}
      <div className={pendingDetailCss.holder}>
        <div className={pendingDetailCss.head}>
          <GobackButton />
          <h2>Pending Order Details</h2>
        </div>

        <div className={pendingDetailCss.information}>
          <div className={pendingDetailCss.customerDetails}>
            <h3>Invoice #{myParams?.orderid}</h3>
            <p>
              <strong>Customer Name</strong> : {orderData?.name}
            </p>
            <p>
              <strong>Phone</strong> : {orderData?.Phone}
            </p>
            <div className={pendingDetailCss.address}>
              <p>
                <strong>Division</strong> :{orderData?.Divition}
              </p>
              <p>
                <strong>District</strong> : {orderData?.District}
              </p>
              <p>
                <strong>area</strong> : {orderData?.Area}
              </p>
            </div>
            <div className={pendingDetailCss.addressSec}>
              {orderData?.pickupPoint ? (
                <p>
                  <strong>Pickup Point</strong> : {orderData?.pickupPoint}
                </p>
              ) : (
                <p>
                  <strong>Delivery Address</strong> : {orderData?.Address}
                </p>
              )}
            </div>
          </div>
          <div className={pendingDetailCss.order_details}>
            <h3>Order Details</h3>
            <p>Order Date : {convertDate(orderData?.order_created / 1000)} </p>
            <p>Payment Type : {orderData?.paymentType}</p>
          </div>
        </div>
        <div className={pendingDetailCss.table_wrapper}>
          <table>
            <tbody>
              <tr>
                <th>SL</th>
                <th>Item</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total Ammount</th>
              </tr>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {item[0].name}
                      {item.map((vitem, vindex) => {
                        return (
                          <span key={vindex + "v"}>
                            {" "}
                            {vitem.Variance_name
                              ? `(${vitem.Variance_name} - ${vitem.quantity}), `
                              : ""}
                          </span>
                        );
                      })}
                    </td>
                    <td>{item[0]?.orPrice}</td>
                    <td>{getQTotal(item)}</td>
                    <td>{totalAmmount(item)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={pendingDetailCss.payment_info}>
            <div>
              <p>Payment Type</p>
              <p>: {orderData?.paymentType}</p>
            </div>
            <div>
              <p>Discount</p>
              <p>: {orderData?.discount ? orderData?.discount : "0"} tk</p>
            </div>
            <div>
              <p>Delivery Charge</p>
              <p>: {orderData?.deliveryCharge} tk</p>
            </div>
            <div>
              <p>Total Ammount</p>
              <p>: {orderData?.grandTotal} tk</p>
            </div>
            <div>
              <p>Collected</p>
              <p>
                : {orderData?.receiveAmmount ? orderData?.receiveAmmount : "0"}{" "}
                tk
              </p>
            </div>
          </div>
          <form
            ref={formRef}
            className={pendingDetailCss.form_section}
            onSubmit={onApproveClick}
          >
            <div className={pendingDetailCss.input_wrapper}>
              <label>Approval note *</label>
              <input
                name="approval_note"
                type="text"
                required
                placeholder="Approval note"
              />
            </div>
            <div className={pendingDetailCss.input_wrapper}>
              <label>Delivery Priority *</label>
              <select required name="priority">
                <option value={""}>Select Priority</option>
                <option value={"High"}>High</option>
                <option value={"Medium"}>Medium</option>
                <option value={"Low"}>Low</option>
              </select>
            </div>

            <div className={pendingDetailCss.input_wrapper}>
              <label>Delivery Date *</label>
              <input name="delivery_date" type="date" required />
            </div>
            <div className={pendingDetailCss.input_wrapper}>
              <label>Special Instruction</label>
              <input
                name="special_instruction"
                type="text"
                placeholder="Special Instruction"
              />
            </div>
            <div className="flex gap-4 items-center col-span-1 xl:col-span-4 justify-end">
              {userInfo.userrole !== 1 ? (
                ""
              ) : (
                <button
                  className="bg-red-500 text-white px-4 py-2"
                  type="button"
                  onClick={onDeclineClick}
                >
                  Decline
                </button>
              )}

              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                type="submit"
                disabled={btnDisabled}
              >
                Approve
              </button>
              <Link
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
                to={`/sales/salesdetails/${myParams?.orderid}/invoice`}
                type="button"
                onClick={onPrintInvoice}
              >
                Print Invoice
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default PendingOrderDetails;
