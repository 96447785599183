import React from "react";
import { Link } from "react-router-dom";

const ManageCustomerMain = () => {
  const crmMenu = [
    {
      title: "Customer List",
      link: "/manage-customer/customer-list",
      icon: "",
    },
    {
      title: "Add New Customer",
      link: "/manage-customer/add-new-customer",
      icon: "",
    },
    { title: "Trash List", link: "/manage-customer/trash-list", icon: "" },
    { title: "Send Message", link: "/manage-customer/send-message", icon: "" },
    { title: "Wish List", link: "/manage-customer/wishlist", icon: "" },
    { title: "Manage Complain", link: "/manage-customer/complain", icon: "" },
  ];
  return (
    <div className="p-4 pb-[70px]">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {crmMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
              key={index}
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ManageCustomerMain;
