import React from "react";

import { UpdateCustomerData } from "../../../api/CustomerApi";
const EditCustomer = ({ popUp, setPopUp }) => {
  async function onUpdateClick(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const email = e.target.email.value;
    const about = e.target.about.value;
    // console.log(popUp);

    // console.log({name, email, about});
    try {
      await UpdateCustomerData(popUp.id, { name, email, about });
    } catch (error) {}
    setPopUp();
    document.body.style.overflow = "auto";
  }
  function ClosePop() {
    setPopUp();
    document.body.style.overflow = "auto";
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-[101]">
      <div className="bg-white w-1/2 rounded-lg overflow-auto">
        {/* <div className="w-16 m-auto">
          {popUp?.userImage ? (
            <img
              name="userImage"
              src={!popUp?.fakeUrl ? popUp.userImage : popUp?.fakeUrl}
              alt="user image here"
            />
          ) : (
            ""
          )}

          {!popUp?.userImage ? (
            <img
              name="userImage"
              src={!popUp?.fakeUrl ? DummyImage : popUp?.fakeUrl}
              alt="user image here"
            />
          ) : (
            ""
          )}
        </div> */}
        <div className="bg-gray-200 p-2 text-2xl text-center font-semibold">
          <p>Update User Information</p>
        </div>
        <form onSubmit={onUpdateClick} className="p-4 space-y-2">
          <div className="flex items-center">
            <label className="flex-1">Full Name</label>
            <input
              name="name"
              type="text"
              placeholder="Full Name"
              defaultValue={popUp?.name}
              required
              className="flex-[2] border border-gray-300 p-2 rounded-md outline-none focus:outline-none"
            />
          </div>
          <div className="flex items-center">
            <label className="flex-1">Email</label>
            <input
              name="email"
              type="text"
              placeholder="Email"
              defaultValue={popUp?.email}
              className="flex-[2] border border-gray-300 p-2 rounded-md outline-none focus:outline-none"
            />
          </div>
          <div className="flex items-center">
            <label className="flex-1">Phone</label>
            <input
              name="notChangeable"
              type="text"
              disabled
              placeholder="Phone"
              defaultValue={popUp?.phone}
              className="flex-[2] border border-gray-300 p-2 rounded-md outline-none focus:outline-none"
            />
          </div>
          <div className="flex items-center">
            <label className="flex-1">Bio</label>
            <input
              name="about"
              type="text"
              placeholder="about"
              defaultValue={popUp?.about}
              className="flex-[2] border border-gray-300 p-2 rounded-md outline-none focus:outline-none"
            />
          </div>

          <div className="flex items-center">
            <label className="flex-1">Upload Image</label>
            <input name="userImageUpdated" type="file" className="flex-[2]" />
          </div>
          <div className="flex items-start justify-center gap-4">
            <button
              type="button"
              onClick={ClosePop}
              className="bg-red-500 text-white rounded-md px-4 py-2"
            >
              Close
            </button>
            <button
              type="submit"
              className="bg-green-500 text-white rounded-md px-4 py-2"
            >
              Update Info
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditCustomer;
