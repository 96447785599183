import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

//
import { useDispatch, useSelector } from "react-redux";
import { fetchBrands } from "./ReduxFiles/Slices/BrandListSlice";
import {
  fetchCategory,
  selectCategoryStatus,
} from "./ReduxFiles/Slices/CategorySlice";
import {
  fetchProducts,
  setProductList,
  setProductStatus,
} from "./ReduxFiles/Slices/ProductSlice";
import { fetchAllUser, selectIsLogedIn } from "./ReduxFiles/Slices/UserSlice";

// new imports
import AccountMain from "./AllContent/Accounts/AccountMain";
import AccountRoutes from "./AllContent/Accounts/AccountRoutes";
import AdminFooter from "./AllContent/admin_footer";
import BlogRoutes from "./AllContent/Blog/BlogRoutes";
import Dashboard from "./AllContent/Dashboard/Dashboard";
import ExpenseMain from "./AllContent/Expense/ExpenseMain";
import ExpenseRoutes from "./AllContent/Expense/ExpenseRoutes";
import AllRoutes from "./AllContent/item_management/AllRoutes";
import ProductManagement from "./AllContent/item_management/ProductManagement";
import CourierRatio from "./AllContent/manageCustomer/Courier/CourierRatio";
import CustomerRoutes from "./AllContent/manageCustomer/CustomerRoutes";
import ManageCustomerMain from "./AllContent/manageCustomer/ManageCustomerMain";
import PurchaseMain from "./AllContent/purchase/PurchaseMain";
import PurchaseRoute from "./AllContent/purchase/PurchaseRoute";
import ReportMain from "./AllContent/Report/ReportMain";
import ReportRoutes from "./AllContent/Report/ReportRoutes";
import SalesMain from "./AllContent/sales/SalesMain";
import SalesRoutes from "./AllContent/sales/SalesRoutes";
import ProfileRoutes from "./AllContent/Setting/ManageUser/MyProfile/ProfileRoutes";
import UserProfile from "./AllContent/Setting/ManageUser/MyProfile/UserProfile";
import SettingMain from "./AllContent/Setting/SettingMain";
import SettingRoutes from "./AllContent/Setting/SettingRoutes";

const AllCombine = () => {
  const isLogedIn = useSelector(selectIsLogedIn);
  const categoryStatus = useSelector(selectCategoryStatus);
  const myDispatch = useDispatch();
  useEffect(() => {
    function getPrimaryData() {
      if (categoryStatus === "idle") {
        myDispatch(fetchCategory());
        myDispatch(fetchBrands());
      }
    }
    getPrimaryData();
  }, [categoryStatus, myDispatch]);

  useEffect(() => {
    async function getAllProductList() {
      let localProducts = window.localStorage.getItem("allProduct");
      if (localProducts && !localProducts === "undefined") {
        myDispatch(setProductList(JSON.parse(localProducts)));
        myDispatch(setProductStatus("success"));
      } else {
        myDispatch(fetchProducts());
      }
      myDispatch(fetchAllUser());
    }
    getAllProductList();
  }, [isLogedIn, myDispatch]);

  return (
    <div className="w-full">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {/* <Route path="/" element={<p className="w-full py-10">j</p>} /> */}
          <Route path="/profile" element={<UserProfile />} />
          <Route path="/courier-ratio" element={<CourierRatio />} />
          <Route path="/profile/*" element={<ProfileRoutes />} />
          <Route path="/products" element={<ProductManagement />} />
          <Route path="/products/*" element={<AllRoutes />} />

          <Route path="/sales" element={<SalesMain />} />
          <Route path="/sales/*" element={<SalesRoutes />} />

          <Route path="/purchase" element={<PurchaseMain />} />
          <Route path="/purchase/*" element={<PurchaseRoute />} />

          <Route path="/reports" element={<ReportMain />} />
          <Route path="/reports/*" element={<ReportRoutes />} />

          <Route path="/accounts" element={<AccountMain />} />
          <Route path="/accounts/*" element={<AccountRoutes />} />

          <Route path="/expense" element={<ExpenseMain />} />
          <Route path="/expense/*" element={<ExpenseRoutes />} />

          <Route path="/manage-customer" element={<ManageCustomerMain />} />
          <Route path="/manage-customer/*" element={<CustomerRoutes />} />

          <Route path="/site-setting" element={<SettingMain />} />
          <Route path="/site-setting/*" element={<SettingRoutes />} />
          <Route path="/blog/*" element={<BlogRoutes />} />
        </Routes>
      </div>
      <AdminFooter />
    </div>
  );
};

export default AllCombine;
