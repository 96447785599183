import React from "react";
import { Link } from "react-router-dom";
import styles from "./blogmain.module.css";
export default function BlogMain() {
  const blogMenu = [
    { title: "Blog List", link: "blog-list" },
    { title: "Add New Blog", link: "add-new-blog" },
    { title: "Blog Categories", link: "blog-categories" },
    { title: "Blog Keywords", link: "blog-keywords" },
    { title: "Blog Images", link: "blog-images" },
  ];
  return (
    <div className="p-4 pb-[70px]">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {blogMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              key={index}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
            >
              {item.title}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
