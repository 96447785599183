import { Outlet } from "react-router-dom";
import AdminFooter from "./AllContent/admin_footer";
import SideNavBar from "./SideNavBar";
import TopBar from "./TopBar";

export default function AppLayout() {
  return (
    <div>
      <SideNavBar />
      <TopBar />
      <main>
        <div className="ml-0 md:ml-[190px]">
          <Outlet />
        </div>
      </main>
      <AdminFooter />
    </div>
  );
}
