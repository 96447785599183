import React from "react";
import { Link } from "react-router-dom";

const PurchaseMain = () => {
  const purchaseMenu = [
    { title: "Pending Purchase", link: "/purchase/pending", icon: "" },
    { title: "Purchase History", link: "/purchase/history", icon: "" },
    { title: "New Purchase", link: "/purchase/new-purchase", icon: "" },
    { title: "Decline Purchase", link: "/purchase/decline", icon: "" },
    { title: "Purchase Return", link: "/purchase/purchase-return", icon: "" },
    {
      title: "Purchase Return History",
      link: "/purchase/purchase-return-history",
      icon: "",
    },
    { title: "Stock Details", link: "/purchase/stock-details", icon: "" },
    { title: "Stock Request", link: "/purchase/stock-request", icon: "" },
  ];

  return (
    <div className="p-4 pb-[70px]">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {purchaseMenu.map((item, index) => {
          return (
            <Link
              key={index}
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default PurchaseMain;
