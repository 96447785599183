import React, { useEffect, useState } from "react";
import Headerimage from "../../assets/Headerimage.jpg";
import QRcode from "../../assets/anayase_qrcode.png";
import { GenarateText } from "../connector/InWord";
import { RearrangeDateThree } from "../MyFunctions/MyFunctions";
import { FaBangladeshiTakaSign } from "react-icons/fa6";
const Invoice = () => {
  const [invoiceState, setInvoiceState] = useState();

  function PrintPaper() {
    window.print();
  }
  function convertDateTime(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myTime;
  }
  const [extraInfo, setExtrainfo] = useState({});

  async function getData() {
    let data = JSON.parse(localStorage.getItem("invoiceData"));
    setInvoiceState(data);
    getGrandTotal(data?.tableData);
  }
  useEffect(() => {
    getGrandTotal(invoiceState?.tableData);
  }, [invoiceState]);

  function getGrandTotal(data) {
    let total = 0;
    let saleTotal = 0;
    let myData = [];
    data?.forEach((element) => {
      element?.forEach((melement) => {
        myData.push(melement);
      });
    });

    myData.forEach((element) => {
      total += element.orPrice * element.quantity - (element.rtotal || 0);
      saleTotal += element.price * element.quantity - (element.rtotal || 0);
    });
    let percentDis = invoiceState?.discountPercent
      ? (total * invoiceState?.discountPercent) / 100
      : 0;
    //  console.log(percentDis);
    let discount = total - saleTotal + percentDis;
    setExtrainfo({ total, saleTotal, discount });
  }

  useEffect(() => {
    getData();
  }, []);

  function getQTotal(item) {
    let total = 0;
    item?.forEach((element) => {
      total += element.quantity - element.rquantity || 0;
    });

    return total;
  }
  function totalAmmount(item) {
    let quantity = getQTotal(item);
    let total = 0;
    total += item[0].orPrice;
    return total * quantity;
  }

  const tableItem = invoiceState?.tableData
    ?.filter((fitem) => {
      return getQTotal(fitem) > 0 ? fitem : "";
    })
    .map((item, index) => {
      return (
        <InvoiceTable
          sl={index + 1}
          description={item[0].name}
          varName={
            item[0].hasVriance ? (
              <>
                {item.map((mainItem) => {
                  return (
                    <>
                      {mainItem.Variance_name} : {mainItem.quantity} pcs
                      {mainItem.attr_value_name
                        ? ` (${mainItem.attr_value_name})`
                        : ""}
                      <br />
                    </>
                  );
                })}
              </>
            ) : (
              ""
            )
          }
          quantity={getQTotal(item)}
          uniteprice={item[0].orPrice}
          total={totalAmmount(item)}
        />
      );
    });
  console.log(invoiceState);

  return (
    <div className="fixed z-[102] inset-0 bg-white h-[100vh] overflow-auto">
      <button
        className="fixed right-52 top-5 bg-teal-600 text-white py-2 px-4 print:hidden"
        onClick={PrintPaper}
      >
        Print
      </button>

      <section className="w-1/2 mx-auto  print:w-full print:px-6">
        <div className="w-full py-2" id="printInvoice">
          <img src={Headerimage} alt="" />

          <div className="flex flex-col gap-2">
            <p className="font-bold text-2xl text-center">INVOICE / BILL</p>
            <div className="flex gap-2">
              <div className="flex-[2]">
                <p>
                  <strong>Invoice Number :</strong> #{invoiceState?.id}
                </p>

                <p className="capitalize">
                  <strong>Customar Name :</strong> {invoiceState?.name}
                </p>
                <p>
                  <strong>Customer Phone :</strong> {invoiceState?.Phone}
                </p>
                <p className="capitalize">
                  <strong>Address :</strong>{" "}
                  {/* {invoiceState?.Address
                    ? `${invoiceState?.Address}, ${invoiceState?.Area}, ${invoiceState?.District}, ${invoiceState?.Divition}`
                    : ""} */}
                  {`${invoiceState?.Address}, ${invoiceState?.District}, ${invoiceState?.Divition}`}
                </p>
              </div>
              <div className="flex-1">
                <p className="text-right">
                  <strong>Date :</strong>{" "}
                  {RearrangeDateThree(
                    new Date(invoiceState?.order_created).toLocaleDateString()
                  )}
                </p>
                <p className="text-right">
                  <strong>Time :</strong>{" "}
                  {convertDateTime(invoiceState?.order_created)}
                </p>
                <p className="text-right">
                  <strong>Sold By :</strong> Anayase.com
                </p>
              </div>
            </div>
            <div className="">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="border border-black p-2">SI No.</th>
                    <th className="border border-black p-2">Item Name</th>
                    <th className="border border-black p-2">Quantity</th>
                    <th className="border border-black p-2">Unit Price</th>
                    <th className="border border-black p-2">Total</th>
                  </tr>
                </thead>
                <tbody>{tableItem}</tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3}>
                      <strong>In Word :</strong>{" "}
                      {GenarateText(
                        Math.round(
                          extraInfo.total -
                            extraInfo.discount +
                            invoiceState?.deliveryCharge
                        )
                      )}
                    </td>
                    <td className="border border-black p-2 text-right">
                      Sub Total
                    </td>
                    <td className="border border-black text-center p-2">
                      <p className="flex gap-1 items-center justify-end font-bold">
                        {extraInfo.total} <FaBangladeshiTakaSign />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>
                    <td className="border border-black p-2 text-right">
                      Discount(-)
                    </td>
                    <td className="border border-black text-center p-2">
                      <p className="flex gap-1 items-center justify-end font-bold">
                        {extraInfo.discount?.toFixed()}{" "}
                        <FaBangladeshiTakaSign />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>
                    <td className="border border-black p-2 text-right">
                      Delivery Charge
                    </td>
                    <td className="border border-black text-center p-2">
                      <p className="flex gap-1 items-center justify-end font-bold">
                        {invoiceState?.deliveryCharge} <FaBangladeshiTakaSign />
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}></td>
                    <td className="border border-black p-2 text-right">
                      Grand Total
                    </td>
                    <td className="border border-black text-center p-2">
                      <p className="flex gap-1 items-center justify-end font-bold">
                        {(
                          extraInfo.total -
                          extraInfo.discount +
                          invoiceState?.deliveryCharge
                        ).toFixed()}
                        <FaBangladeshiTakaSign />
                      </p>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div className="flex flex-col gap-2">
                <p className="font-bold">
                  NOTE : {invoiceState?.special_instruction || "no note"}
                </p>
                <div className="w-32">
                  <img src={QRcode} alt="" />
                </div>
              </div>
            </div>

            <div className="flex justify-end">
              {/* <span>Customer Signature</span> */}
              <p>Authority Signature</p>
            </div>
            <div className="flex items-center justify-center gap-2">
              Thank you for being with
              <strong style={{ fontSize: "18px" }}>anayase.com</strong>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Invoice;

const InvoiceTable = ({
  sl,
  description,
  varName,
  ageName,
  quantity,
  uniteprice,
  total,
}) => {
  return (
    <tr>
      <td className="border border-black text-center p-2">{sl}</td>
      <td className="border border-black p-2">
        {description}{" "}
        <span style={{ color: "#424242", display: "flex" }}>
          {varName} {ageName}
        </span>
      </td>
      <td className="border border-black p-2 text-center">{quantity}</td>
      <td className="border border-black p-2 text-center">{uniteprice}</td>
      <td className="border border-black p-2 text-center">{total}</td>
    </tr>
  );
};
