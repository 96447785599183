import React from "react";
import { Link } from "react-router-dom";

const SalesMain = () => {
  const salesMenu = [
    { title: "Create Order", link: "/sales/create-order", icon: "" },
    { title: "Unverified Sales", link: "/sales/unverified", icon: "" },
    {
      title: "Unverified Sales (Declined)",
      link: "/sales/unverified-declined",
      icon: "",
    },
    { title: "Pending Sales", link: "/sales/pending", icon: "" },
    { title: "Sale History", link: "/sales/history", icon: "" },
    { title: "Complete Orders", link: "/sales/complete", icon: "" },
    { title: "Decline Orders", link: "/sales/decline", icon: "" },
    { title: "Sales Return", link: "/sales/sales-return", icon: "" },
    { title: "Sales Return History", link: "/sales/return-history", icon: "" },
    { title: "Offer Items", link: "/sales/offer-items", icon: "" },
    { title: "Offer Report", link: "/sales/offer-report", icon: "" },
    { title: "Delivery Management", link: "/sales/delivery-manage", icon: "" },
    { title: "Buy 1 Get 1", link: "/sales/bogo", icon: "" },
    { title: "Invoice Wise Sales Report", link: "/sales/invoice", icon: "" },
    { title: "Product Wise Sales Report", link: "/sales/product", icon: "" },
  ];

  return (
    <div className="p-4">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {salesMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
              key={index}
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SalesMain;
