import React, { useEffect, useState } from "react";
import Table from "../Table";
import ConfirmAlert from "../smallThings/ConfirmAlert";
import ToggleSwitch from "../smallThings/ToggleSwitch";
import {
  DeleteSliderData,
  GetSlideData,
  SaveSlideData,
  UpdateSlideData,
  UpdateSlideSLNO,
} from "../../api/SettingApi";
import { API_LINK } from "../../api/APILink";
import ManageSlidesCss from "./css/ManageSlides.module.css";
import { toast } from "react-toastify";

const ManageSlides = () => {
  const [currentState, setCurrentState] = useState({
    name: "",
    slider_url: "",
    alter_text: "",
    slider_type: "",
  });
  const [tableData, setTableData] = useState();
  const [conAlert, setConAlert] = useState(false);
  const [editData, setEditData] = useState();
  const [showArranger, setShowArranger] = useState(false);

  async function onSaveClick(e) {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const alter_text = form.alter_text.value;
    const slider_url = form.slider_url.value;
    const slider_type = form.slider_type.value;
    const created_date = new Date().getTime();
    let slideImage = form.slideImage.files[0];
    try {
      await SaveSlideData(
        { name, alter_text, slider_url, slider_type, created_date },
        slideImage
      );
      form.reset();
      getAllData();
      toast.success("Slider Saved");
    } catch (error) {
      toast.error(error.message);
    }
  }

  function EditClick(data) {
    setEditData(data);
  }

  const [deleteData, setDeleteData] = useState();
  function DeletClick(item) {
    setDeleteData(item);
    setConAlert(true);
  }
  async function ClickYes() {
    const id = deleteData.id;
    const image_name = deleteData.image_name;
    await DeleteSliderData(id, image_name);
    setConAlert(false);
    getAllData();
  }

  function ClickNo() {
    setConAlert(false);
    setCurrentState({
      name: "",
      url: "",
      alterText: "",
      slideOption: "",
    });
  }

  async function getAllData() {
    let myData = await GetSlideData();
    let sortArr = myData.sort((a, b) => (a.sl_no > b.sl_no ? 1 : -1));
    setTableData(sortArr);
  }

  useEffect(() => {
    if (!editData) {
      getAllData();
    }
  }, [editData]);

  async function onItemSwitchClick(item) {
    let isActice = 0;
    if (item.active === 0) {
      isActice = 1;
    } else {
      isActice = 0;
    }
    //console.log(!item.active)
    await UpdateSlideData(item.id, {
      active: isActice,
      image_name: item.image_name,
    });
    //    console.log(item.id)
    let tempArr = [];
    tableData?.map((okItem) => {
      if (okItem.id === item.id) {
        okItem = {
          ...okItem,
          active: isActice,
        };
      }
      tempArr.push(okItem);
    });
    setTableData(tempArr);
  }

  function onArrangerClick() {
    setShowArranger(true);
  }

  const TableItem = (
    <>
      {tableData
        ? tableData.map((item, index) => {
            return (
              <Table
                key={index}
                param1={index + 1}
                param2={
                  item.image_name ? (
                    <img src={API_LINK + "slider_images/" + item.image_name} />
                  ) : (
                    <img src={item.fakeslideImage} />
                  )
                }
                param3={item.alter_text}
                param4={item.slider_type ? item.slider_type : "no data"}
                param5={item.slider_url}
                param6={
                  <ToggleSwitch
                    toggleOnOff={item.active}
                    toggleClick={() => {
                      onItemSwitchClick(item);
                    }}
                  />
                }
                param7={
                  <div className={ManageSlidesCss.action_buttons}>
                    <input
                      onClick={() => {
                        EditClick(item);
                      }}
                      type="Submit"
                      defaultValue="Edit"
                      sid={item.id}
                    />
                    <input
                      className={ManageSlidesCss.delete}
                      onClick={() => {
                        DeletClick(item);
                      }}
                      type="Submit"
                      defaultValue="Delete"
                    />
                  </div>
                }
              />
            );
          })
        : ""}
    </>
  );

  return (
    <section className={ManageSlidesCss.salesMain}>
      {conAlert ? <ConfirmAlert yesClick={ClickYes} noClick={ClickNo} /> : ""}
      {editData ? <Edit editData={editData} setEditData={setEditData} /> : ""}

      {showArranger ? (
        <MyArranger slideData={tableData} setShowArranger={setShowArranger} />
      ) : (
        ""
      )}

      <h2 className={ManageSlidesCss.itemlisth3}>Banner Image List</h2>
      <div className={ManageSlidesCss.innerSale}>
        <form className={ManageSlidesCss.comp_wrap} onSubmit={onSaveClick}>
          <div className={ManageSlidesCss.searchSection}>
            <div className={ManageSlidesCss.common}>
              <label>Name</label>
              <input type="text" name="name" required />
            </div>
            <div className={ManageSlidesCss.common}>
              <label>Alter Text</label>
              <input type="text" name="alter_text" required />
            </div>
            <div className={ManageSlidesCss.common}>
              <label>URL</label>
              <input type="text" name="slider_url" required />
            </div>
            <div className={ManageSlidesCss.common}>
              <label>Slider Type</label>
              <select
                name="slider_type"
                defaultValue={currentState.slider_type}
                required
              >
                <option value={""}>Select One</option>
                <option value={"Mobile"}>Mobile</option>
                <option value={"Web"}>Web</option>
              </select>
            </div>
            <div className={ManageSlidesCss.common}>
              <label>Slider Image</label>
              <input type="file" name="slideImage" required />
            </div>
          </div>
          <div className={ManageSlidesCss.buttonWrapper}>
            <button>Save Slider</button>
            <button onClick={onArrangerClick}>Re-Arrange Slide</button>
          </div>
        </form>

        <div className={ManageSlidesCss.tableSection}>
          <table>
            <tbody>
              <tr>
                <th>Sl</th>
                <th>Images</th>
                <th>Alter Text</th>
                <th>Slider Type</th>
                <th>URL</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
              {TableItem}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ManageSlides;

const MyArranger = ({ slideData, setShowArranger }) => {
  function dragFunction() {
    const items = document.querySelectorAll("#sortable div");
    let currentDraggedItem;
    for (const item of items) {
      item.addEventListener("dragstart", (event) => {
        currentDraggedItem = event.target;
        event.dataTransfer.setData("text/plain", "");
      });

      item.addEventListener("dragover", (event) => {
        event.preventDefault();
      });

      item.addEventListener("drop", (event) => {
        event.preventDefault();
        event.target.parentNode.insertBefore(currentDraggedItem, event.target);
      });
    }
  }

  function onUpdateClick() {
    const items = document.querySelectorAll("#sortable div");
    let mitem = [];
    items.forEach((element) => {
      mitem.push(element);
    });
    let myPromises = mitem?.map(async function (item, index) {
      await UpdateSlideSLNO(item.getAttribute("data"), { sl_no: index + 1 });
    });
    Promise.all(myPromises).then(() => {
      setShowArranger(false);
    });
  }

  function onCloseClick() {
    setShowArranger(false);
  }

  useEffect(() => {
    dragFunction();
  }, []);

  return (
    <>
      <div className={ManageSlidesCss.re_arrange_slide}>
        <div className={ManageSlidesCss.popUp}>
          <div className={ManageSlidesCss.header}>
            <h2>Re-arrange</h2>
            <div className="flex justify-end mt-[-30px] py-2 gap-4">
              <button
                className="text-white font-bold hover:bg-red-600 transition-all duration-300 bg-red-500 px-4 py-2 rounded-[4px]"
                onClick={onCloseClick}
              >
                Close
              </button>
              <button
                className="text-white hover:bg-green-600 transition-all duration-300 font-bold bg-green-500 px-4 py-2 rounded-[4px]"
                onClick={onUpdateClick}
              >
                Update
              </button>
            </div>
          </div>
          <div id="sortable">
            {slideData?.map((item, index) => {
              return (
                <div
                  className="flex justify-between gap-4 items-center justify-center border p-2 m-2 rounded-md"
                  key={index}
                  draggable="true"
                  data={item.id}
                >
                  <p className="text-center md:text-xl text-md">
                    {item.alter_text}
                  </p>
                  <div className="flex items-center gap-2">
                    <img
                      className="rounded-md  h-[50px] w-full"
                      src={API_LINK + "slider_images/" + item.image_name}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const Edit = ({ editData, setEditData }) => {
  async function handleUpdate(e) {
    e.preventDefault();
    const form = e.target;
    const name = form.name.value;
    const alter_text = form.alter_text.value;
    const slider_url = form.slider_url.value;
    const slider_type = form.slider_type.value;
    const image_name = editData.image_name;
    const created_date = new Date().getTime();
    let slideImage = form.slideImage.files[0];
    // console.log({name, alter_text, slider_url, slider_type, image_name, created_date}, slideImage, editData.id);
    try {
      await UpdateSlideData(
        editData.id,
        { name, alter_text, slider_url, slider_type, image_name, created_date },
        slideImage
      );
      toast.success("Slider Updated");
      setEditData();
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className={ManageSlidesCss.edit_popup_wrapper}>
      <form className={ManageSlidesCss.edit_popup} onSubmit={handleUpdate}>
        <div className={ManageSlidesCss.common}>
          <label>Name*</label>
          <input
            name="name"
            defaultValue={editData?.name}
            placeholder="Slider Name here"
            type="text"
            required
          />
        </div>
        <div className={ManageSlidesCss.common}>
          <label>URL*</label>
          <input
            name="slider_url"
            defaultValue={editData?.alter_text}
            placeholder="Slider Url here"
            type="text"
            required
          />
        </div>

        <div className={ManageSlidesCss.common}>
          <label>Alter Text*</label>
          <input
            name="alter_text"
            defaultValue={editData?.alter_text}
            placeholder="Slider Url here"
            type="text"
            required
          />
        </div>

        <div className={ManageSlidesCss.common}>
          <label>Slider Type</label>
          <select
            name="slider_type"
            defaultValue={editData?.slider_type}
            required
          >
            <option value={""}>Select One</option>
            <option value={"Mobile"}>Mobile</option>
            <option value={"Web"}>Web</option>
          </select>
        </div>
        <div className={ManageSlidesCss.common}>
          <label>Slide Image</label>
          <input
            name="slideImage"
            defaultValue={editData?.slideImage}
            placeholder="Slider Url here"
            type="file"
          />
        </div>
        <div className={ManageSlidesCss.common}>
          <img src={`${API_LINK}/slider_images/${editData?.image_name}`} />
        </div>
        <div className={ManageSlidesCss.update_btns}>
          <button type="submit">Update Data</button>
          <button type="button" onClick={() => setEditData()}>
            Cancel Update
          </button>
        </div>
      </form>
    </div>
  );
};
