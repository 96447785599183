import React from "react";
import { Link } from "react-router-dom";
import accountCss from "./Css/AccountMain.module.css";
const AccountMain = () => {
  const accountMenu = [
    { title: "Debit Voucher", link: "debit-voucher" },
    { title: "Debit Voucher History", link: "debit-voucher-history" },
    { title: "Credit Voucher", link: "credit-voucher" },
    { title: "Credit Voucher History", link: "credit-voucher-history" },
    { title: "Day Book", link: "day-book" },
    { title: "Cash", link: "cash" },
    { title: "SSL", link: "ssl" },
    { title: "Add New Bank", link: "add-bank" },
    { title: "Bank List", link: "bank-list" },
    { title: "Manage-cheque", link: "manage-cheque" },
    { title: "Bank Balance List", link: "bank-balance-list" },
    { title: "Bank Transaction History", link: "bank-transaction-history" },
    { title: "Deposite", link: "deposite" },
    { title: "Withdraw", link: "withdraw" },
    { title: "Transaction in", link: "transaction-in" },
    { title: "Transaction out", link: "transaction-out" },
    { title: "Cheque in", link: "cheque-in" },
    { title: "Cheque in History", link: "cheque-in-history" },
    { title: "Cheque out", link: "cheque-out" },
    { title: "Cheque out History", link: "cheque-out-history" },
    // { title: 'Check Api', link: '/check-api' },
  ];

  return (
    <div className="p-4 pb-[70px]">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {accountMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
              key={index}
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default AccountMain;
