import React, { useEffect, useState } from "react";
import { API_LINK } from "../../api/APILink";
import Table from "../Table";
import ConfirmAlert from "../smallThings/ConfirmAlert";
import LoadingShow from "../smallThings/LoadingShow";
import ToggleSwitch from "../smallThings/ToggleSwitch";
import ManageTagCss from "./css/ManageTag.module.css";

const ManageTag = () => {
  const ImageLink = API_LINK;
  const [myOverlay, setMyOverlay] = useState(false);
  const [bValue, setbValue] = useState("Save Data");
  const [tableData, setTabledata] = useState([]);
  const [conAlert, setConAlert] = useState(false);
  const [loader, setLoader] = useState(false);

  const [currentState, setCurrentState] = useState({
    tag_name: "",
    meta_title: "",
    meta_desc: "",
    website_visibility: true,
    tag_f_image: "",
    tag_banner: "",
  });

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setCurrentState({ ...currentState, [name]: value });
  }

  function ImageChange(e) {
    let name = e.target.name;
    if (e.target.files[0]) {
      if (name === "tag_f_image") {
        let fake_tag_f_image = genarateFakeUrl(e.target.files[0]);
        setCurrentState({
          ...currentState,
          [name]: e.target.files[0],
          fake_tag_f_image: fake_tag_f_image,
        });
      } else {
        let fake_tag_banner = genarateFakeUrl(e.target.files[0]);
        setCurrentState({
          ...currentState,
          [name]: e.target.files[0],
          fake_tag_banner: fake_tag_banner,
        });
      }
    }
  }

  function addnewPop() {
    setMyOverlay(true);
  }

  const ClickToSave = async (e) => {
    if (bValue === "Save Data") {
      mySaveData();
    } else {
      myUpdateData();
    }
  };

  async function mySaveData() {
    let data = {
      tag_name: currentState.tag_name,
      meta_title: currentState.meta_title,
      meta_desc: currentState.meta_desc,
      website_visibility: false,
      created_date: new Date().getTime(),
    };
    // let itemID = await SaveDataExpress('manage_tag', data)
    // await UploadImageAndUpdateDataExpress(currentState.tag_f_image, 'manage_tag', 'tag_f_image', itemID)
    // await UploadImageAndUpdateDataExpress(currentState.tag_banner, 'manage_tag', 'tag_banner', itemID)
    closeOverlay();
    //  await UploadImageAndUpdateDataExpress(currentState.tag_f_image, 'manage_tag', 'tag_f_image', itemID)

    //  console.log(currentState)
  }

  const [myPrevState, setMyPrevSate] = useState();

  function EditClick(item) {
    setbValue("Update Data");
    setMyPrevSate(item);
    setCurrentState(item);
    addnewPop();
  }
  async function myUpdateData() {
    let tag_f_image = currentState.tag_f_image;
    let tag_banner = currentState.tag_banner;
    let data = {
      tag_name: currentState.tag_name,
      meta_title: currentState.meta_title,
      meta_desc: currentState.meta_desc,
    };
    // await UpdateItemExpress('manage_tag', { ...data }, currentState.id)
    if (typeof tag_f_image === "object") {
      // await DeleteImageExpress('uploads', myPrevState.tag_f_image)
      // await UploadImageAndUpdateDataExpress(tag_f_image, 'manage_tag', 'tag_f_image', currentState.id)
    } else {
    }
    if (typeof tag_banner === "object") {
      // await DeleteImageExpress('uploads', myPrevState.tag_banner)
      // await UploadImageAndUpdateDataExpress(tag_banner, 'manage_tag', 'tag_banner', currentState.id)
    } else {
    }
    setMyPrevSate();
    closeOverlay();
  }

  function genarateFakeUrl(image) {
    let myurl = URL.createObjectURL(image);
    return myurl;
  }

  function ClickSearch(e) {
    e.preventDefault();
    console.log(bValue);
  }

  async function switchClick(item, data) {
    console.log(item.id);
    let myBolean = 0;
    if (item.website_visibility === 1) {
      myBolean = 0;
    } else {
      myBolean = 1;
    }
    //  await UpdateItemExpress('manage_tag', { website_visibility: myBolean }, item.id)
    let tempArr = [];
    tableData.forEach((element) => {
      if (element.id === item.id) {
        element = {
          ...element,
          website_visibility: myBolean,
        };
        tempArr.push(element);
      } else {
        tempArr.push(element);
      }
    });
    setTabledata(tempArr);
  }

  function closeOverlay() {
    setMyOverlay(false);
    setCurrentState({
      tag_name: "",
      meta_title: "",
      meta_desc: "",
      website_visibility: true,
      tag_f_image: "",
      tag_banner: "",
    });
  }
  const [deleteItem, setDeleteItem] = useState();
  function DeletClick(item) {
    setDeleteItem(item);
    setConAlert(true);
  }

  async function Clickyes() {
    // await DeleteItemExpress('manage_tag', deleteItem.id)
    if (deleteItem.tag_f_image) {
      // await DeleteImageExpress('uploads', deleteItem.tag_f_image)
    }
    if (deleteItem.tag_banner) {
      // await DeleteImageExpress('uploads', deleteItem.tag_banner)
    }
    getAllData();
    setConAlert(false);
    setDeleteItem();
  }
  function ClickNo() {
    setConAlert(false);
  }

  async function getAllData() {
    setLoader(true);
    let myData = []; //await GetAllDataExpress("manage_tag")
    setTabledata(myData);
    setLoader(false);
  }
  useEffect(() => {
    getAllData();
  }, []);

  let TableItem = tableData.map((item, index) => {
    const showData = (
      <Table
        key={index}
        param1={index + 1}
        param2={item.tag_name}
        param3={<img src={ImageLink + item.tag_f_image} />}
        param4={<img src={ImageLink + item.tag_banner} />}
        param5={
          <ToggleSwitch
            toggleClick={switchClick}
            toggleOnOff={item.website_visibility}
            item={item}
          />
        }
        param6={
          <div>
            <input
              onClick={() => {
                EditClick(item);
              }}
              type="Submit"
              defaultValue="Edit"
              sid={item.id}
              tagfimagename={item.TagFImageName}
              tagbannername={item.TagBannerName}
            />
            <input
              className="delete"
              onClick={() => {
                DeletClick(item);
              }}
              type="Submit"
              defaultValue="Delete"
              sid={item.id}
              tagfimagename={item.TagFImageName}
              tagbannername={item.TagBannerName}
            />
          </div>
        }
      />
    );

    return showData;
  });

  let myPopBox = (
    <div className={ManageTagCss.popbox}>
      <div id="popup1" className={ManageTagCss.overlay}>
        <div className={ManageTagCss.popup}>
          <h2>Add New Tag</h2>
          <a onClick={closeOverlay} className={ManageTagCss.close}>
            &times;
          </a>
          <div className={ManageTagCss.content}>
            {/* <PopUpInput popname="tag_name" poplabel="Tag Name :" popplaceholder="Tag Name" poptype="text" ondatachange={handleChange} value={currentState.tag_name} />
                    <PopUpInput popname="meta_title" poplabel="Meta Title :" popplaceholder="Meta Title" poptype="text" ondatachange={handleChange} value={currentState.meta_title} />
                    <PopUpInput popname="meta_desc" poplabel="Meta Desc :" popplaceholder="Meta Description" poptype="text" ondatachange={handleChange} value={currentState.meta_desc} />

                    <PopUpInput popname="tag_f_image" poplabel="Tag Feature Image" poptype="file" ondatachange={ImageChange}
                        imageLink={currentState.fake_tag_f_image ? currentState.fake_tag_f_image : ImageLink + currentState.tag_f_image} />
                    <PopUpInput popname="tag_banner" poplabel="Tag Banner" poptype="file" ondatachange={ImageChange}
                        imageLink={currentState.fake_tag_banner ? currentState.fake_tag_banner : ImageLink + currentState.tag_banner} /> */}
            <div className={ManageTagCss.inputcomm}>
              <input
                className={ManageTagCss.button}
                type={"submit"}
                onClick={ClickToSave}
                value={bValue}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <LoadingShow ShowLodign={loader} />
      {conAlert ? <ConfirmAlert yesClick={Clickyes} noClick={ClickNo} /> : ""}
      <div className={`${ManageTagCss.addCatagory} ${ManageTagCss.admincomm}`}>
        <h3 className={ManageTagCss.itemlisth3}>Manage Tag</h3>
        <div className={ManageTagCss.ltemlistsearch}>
          <input
            className={ManageTagCss.submit}
            type={"submit"}
            value="Add New Tag"
            onClick={addnewPop}
          />
          <div className={ManageTagCss.Categorysearch}>
            <input
              className={ManageTagCss.searchOption}
              type="text"
              placeholder="Search.."
              name="search"
            />
            <input
              className={ManageTagCss.searchButton}
              type="submit"
              placeholder="Search"
            />
          </div>

          {myOverlay ? myPopBox : ""}
        </div>
        <div className={ManageTagCss.ManageTagTable}>
          <table>
            <tbody>
              <tr>
                <th>Sl</th>
                <th>Tag Name</th>
                <th>Tag Feature Image</th>
                <th>Tag Banner</th>
                <th>Website Visibility</th>
                <th>Action</th>
              </tr>
              {TableItem}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ManageTag;
