import MyAxios from "axios";
import { API_LINK } from "./APILink";
const MY_API_LINK = `${API_LINK}user/`;

const configData = {
  headers: {
    "Content-Type": "application/json",
  },
};

const token = "aBWMilxzFNUdBPzsygqkH7BzIO520hh6VCKcG7CYkDTfm1jMmxpo7vHcnkIv";
export async function GetDeliveryRation(phone) {
  const result = await MyAxios.post(
    `https://bdcourier.com/api/courier-check?phone=${phone}`,
    {}, // Empty object for the data if no payload is required
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return result;
}

export async function GetTotalCustomer() {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}count/total`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetSingleCustomer(id) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}single/${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetLimitCustomer(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}get-limit-user`,
      data,
      configData
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetBrandWiseCustomer(query) {
  let result = await MyAxios.get(
    `${MY_API_LINK}get-brand-wise-customer?${query}`
  );
  return result.data;
}

export async function GetSearchedCustomer(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}search-user`,
      data,
      configData
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function SaveCustomerDataFromBack(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}admin-save`,
      data,
      configData
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateCustomerPasswordFromBack(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}update-pass`,
      data,
      configData
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateCustomerData(id, passedData) {
  try {
    let result = await MyAxios.put(
      `${MY_API_LINK}update/${id}`,
      passedData,
      configData
    );
    return result.data;
  } catch (error) {
    return error;
  }
}

export async function GetSingleCustomerOrderAddress(id) {
  try {
    let result = await MyAxios.get(`${API_LINK}address/single/${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateCustomerAddress(id, passedData) {
  try {
    let result = await MyAxios.put(
      `${API_LINK}address/update/${id}`,
      passedData,
      configData
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetAllCustomer() {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}all`);
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function SaveCustomerData(data) {
  try {
    let result = await MyAxios.post(`${MY_API_LINK}add`, data, configData);
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function CheckExisitingUser(phone) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}phone/${phone}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}

// customer address api

export async function GetCustomerAddressList(query) {
  try {
    let result = await MyAxios.get(`${API_LINK}address/filter/${query}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
