import React from "react";
import { Link } from "react-router-dom";
import manageCss from "./css/manage.module.css";

const ProductManagement = () => {
  const itemMenu = [
    { title: "Product List", link: "/products/product-list", icon: "" },
    { title: "Add New Product", link: "/products/add-new-product", icon: "" },
    { title: "Categories", link: "/products/category", icon: "" },
    { title: "Brand List", link: "/products/brands", icon: "" },
    { title: "Manage Tag", link: "/products/managetag", icon: "" },
    { title: "Attributes ", link: "/products/atributes", icon: "" },
    { title: "Units", link: "/products/unit", icon: "" },
  ];
  return (
    <div className="p-4">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {itemMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
              key={index}
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default ProductManagement;
