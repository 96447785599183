import React, { useState } from "react";
import Select from "react-select";
import { selectBrandList } from "../../../ReduxFiles/Slices/BrandListSlice";
import { useSelector } from "react-redux";
import { GetBrandWiseCustomer } from "../../../api/CustomerApi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaTimes } from "react-icons/fa";
export default function BrandSelectModel({ popUp, setPopUp, setAddedNumber }) {
  const brandList = useSelector(selectBrandList);
  const [customerList, setCustomerList] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const brand_id = e.target.brand.value;
    const response = await GetBrandWiseCustomer(`brand_id=${brand_id}`);
    setCustomerList(response);
  };
  const handleDelete = (customer) => {
    setCustomerList((prev) =>
      prev.filter((item) => item.phone !== customer.phone)
    );
  };
  const handleAddToList = () => {
    setAddedNumber(customerList);
    setPopUp(false);
    setCustomerList([]);
    document.body.style.overflow = "auto";
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[101]">
      <div className="bg-white rounded-lg overflow-auto min-w-[600px] min-h-[400px] max-h-[90vh]">
        <div className="flex justify-between items-center p-2 px-4 bg-gray-100">
          <p className="text-2xl font-bold  text-center bg-gray-100">
            Get Band Wise Customer List
          </p>
          <button
            type="button"
            onClick={() => {
              setPopUp(false);
              document.body.style.overflow = "auto";
            }}
            className="bg-red-500 text-white  p-2 rounded-md"
          >
            <FaTimes />
          </button>
        </div>
        <form className="flex gap-2 p-4" onSubmit={handleSubmit}>
          <Select
            options={brandList}
            className="flex-1"
            name="brand"
            getOptionLabel={(option) => option.BrandName}
            getOptionValue={(option) => option.id}
            isClearable
            required
          />
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
            Filter
          </button>
          <button
            onClick={handleAddToList}
            type="button"
            className="bg-green-500 text-white px-4 py-2 rounded-md"
          >
            Add to List
          </button>
        </form>

        <div className="p-4 custom-table">
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {customerList.map((customer, index) => (
                <tr key={customer.id}>
                  <td>{index + 1}</td>
                  <td className="text-left">{customer.name}</td>
                  <td>{customer.phone}</td>
                  <td>
                    <button
                      onClick={() => handleDelete(customer)}
                      className="bg-red-400 text-white px-2 py-1 rounded-md"
                    >
                      <RiDeleteBin6Line />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
