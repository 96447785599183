import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import AddNewMenuCss from "./css/AddNewMenu.module.css";
const AddNewMenu = ({ mstate, stateValue, item }) => {
  const tabItem = useRef();

  function CheckPreviousData(index) {
    const requiredFields = [
      "en_sort_title",
      "en_long_title",
      "bn_sort_title",
      "bn_long_title",
      "selected_catagory_id",
      "selected_brand_id",
      "selectedUnit",
      "itemImage",
    ];

    for (const field of requiredFields) {
      if (!item[field]) {
        toast.warn("Please Fill Required Field");
        mstate(1);
        return;
      } else {
        mstate(index);
      }
    }
    if (stateValue === 2 && !item.Selling_price && index === 3) {
      mstate(stateValue);
      toast.warn("Please Fill Required Field");
    } else {
      mstate(index);
    }
    if (stateValue === 2 && !item.Selling_price && index === 4) {
      mstate(stateValue);
      toast.warn("Field");
    } else {
      mstate(index);
    }
  }

  function changeUi(tabnumber) {
    mstate(tabnumber);
    // CheckPreviousData(data)
  }
  return (
    <div ref={tabItem} className="shadow-shadow_box2 p-4 my-2 rounded-md">
      <div className="grid lg:grid-cols-4 grid-cols-2 gap-4">
        <h4
          onClick={() => {
            changeUi(1);
          }}
          className={
            stateValue === 1
              ? "bg-teal-600 p-2 hover:bg-teal-700 rounded-md text-white transition-all duration-300 text-center cursor-pointer"
              : "p-2 border transition-all duration-300 border-gray-300 hover:border-teal-600 rounded-md text-center cursor-pointer"
          }
        >
          Item Information
        </h4>
        <h4
          onClick={() => {
            changeUi(2);
          }}
          className={
            stateValue === 2
              ? "bg-teal-600 p-2 hover:bg-teal-700 rounded-md text-white transition-all duration-300 text-center cursor-pointer"
              : "p-2 border transition-all duration-300 border-gray-300 hover:border-teal-600 rounded-md text-center cursor-pointer"
          }
        >
          Item Price
        </h4>

        <h4
          onClick={() => {
            changeUi(3);
          }}
          className={
            stateValue === 3
              ? "bg-teal-600 p-2 hover:bg-teal-700 rounded-md text-white transition-all duration-300 text-center cursor-pointer"
              : "p-2 border transition-all duration-300 border-gray-300 hover:border-teal-600 rounded-md text-center cursor-pointer"
          }
        >
          Product Attributes
        </h4>

        <h4
          onClick={() => {
            changeUi(4);
          }}
          className={
            stateValue === 4
              ? "bg-teal-600 p-2 hover:bg-teal-700 rounded-md text-white transition-all duration-300 text-center cursor-pointer"
              : "p-2 border transition-all duration-300 border-gray-300 hover:border-teal-600 rounded-md text-center cursor-pointer"
          }
        >
          Uplode Image
        </h4>
      </div>
    </div>
  );
};

export default AddNewMenu;
