import React from "react";
import { Link } from "react-router-dom";

const SettingMain = () => {
  // const myParams = useParams();
  //console.log(myParams.msetting)
  const settingMenu = [
    { link: "web-setting", title: "Website Settings" },
    { link: "slides", title: "Manage Slides" },
    { link: "banners", title: "Manage Banners" },
    { link: "offer-popup", title: "Offer Popup" },
    { link: "page-list", title: "Page List" },
    { link: "add-page", title: "Add New Page" },
    { link: "page-content", title: "Page Content" },
    { link: "get-image-name", title: "Get Image Name" },
    { link: "read-csv", title: "Read CSV File" },
    { link: "manage-user", title: "Manage User" },
    // { link: 'change-password', title: 'Change Password' },
  ];
  return (
    <div className="p-4">
      <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-4">
        {settingMenu.map((item, index) => {
          return (
            <Link
              to={item.link}
              className="bg-white shadow-shadow_box2 aspect-[3/1] p-4 rounded-md hover:bg-teal-50 transition-all duration-300 text-center flex items-center justify-center lg:text-xl text-lg"
              key={index}
            >
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SettingMain;
